<template>
  <div>
    <FlightCard
      v-for="(flight, f) in flightList"
      :key="f"
      :trip="flight"
      :status="status"
      :is-selectable="true"
      @getSelectedTrip="getSelectedTrip"
    />
    <b-modal
      id="trip-option-modal"
      ref="trip-option-modal"
      ok-only
      ok-variant="primary"
      modal-class="modal-primary"
      centered
      title="Select flight"
    >
      <b-card-text>
        Are you sure you want to select this flight ?
      </b-card-text>
      <template #modal-footer>
        <b-button
          variant="primary"
          :disabled="isActionLoading"
          @click="submitFlight"
        >
          <b-spinner
            v-if="isActionLoading"
            small
          />
          Confirm
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import FlightCard from '@/components/flight/FlightCard.vue'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'TripOptionList',
  components: {
    FlightCard,
  },
  mixins: [helper],
  props: {
    flightList: {
      type: Array,
    },
    tripId: {
      type: [String, Number],
    },
    status: {
      type: String,
    },
  },
  data() {
    return {
      trip: {},
      isActionLoading: false,
    }
  },
  methods: {
    getSelectedTrip(trip) {
      this.trip = trip
      this.$refs['trip-option-modal'].show()
    },
    submitFlight() {
      this.isActionLoading = true
      api.updateData(`b2e_dashboard/trip_request/${this.tripId}/`, true, {
        status: 'APPROVED',
        selected_result: this.trip.id,
      }).then(response => {
        if (response.data.status) {
          this.$emit('getTripList')
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Your request has been successfully processed')
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      }).finally(() => {
        this.isActionLoading = false
        this.$refs['trip-option-modal'].hide()
      })
    },
  },
}
</script>

<style scoped>

</style>
